/**
 * Main javascript file for SEGA Social & Legal feed site.
 * This file uses revealing module pattern
 */
sl = function($) {
	
	var transitionSpeed = 300;

    var init = function() {
	    initialiseHome();
	    initialiseShare();
	    initialiseContactForm();
    };

    /*
     * homepage
     */
    var initialiseHome = function() {
    	
    	//do we have some posts?
    	$posts = $('.posts article');
    	if ($posts.length) {
    		initialisePosts($posts);
    	}

		//init infinite scroll if we have a next page of articles.
		var $nav = $('nav.navigation a');
		if ($nav.length>0) {

			$('.posts').infinitescroll({
				navSelector  : 'nav.navigation',
			    nextSelector : 'nav.navigation a',
			    itemSelector : '.posts article',
			    loading: {
			    	msgText: "<p>Loading more articles</p>",
			        finishedMsg: "<p>There are no more articles to display.</p>",
			        img: "/wp-content/themes/social-legal-feed/assets/img/loading.svg"
			    }
			},
	        // call Isotope as a callback
	        function( newElements ) {
				console.log('loading posts', newElements.length )
				initialisePosts($(newElements));
				twttr.widgets.load(
					newElements
				);
			});
		}
		
		//for tracking popular posts
    	if ($posts.length) {
    		$('.posts').on('click', 'article', function() {
    			var $this = $(this);
    			//ga('send', 'event', 'Article', 'click', $this.data('title'), $this.data('id'));
    			/*
    			dataLayer.push({
    				  'event' : 'GAEvent',
    				  'eventCategory' : 'Article',
    				  'eventAction' : 'click',
    				  'eventLabel' : $this.data('title'),
    				  'eventValue' : $this.data('id')
    			});*/
    		});
    	}
		
    	
    };
    
    /*
     * initialise posts
     */
    var initialisePosts = function($posts) {
    	
    	//is there any carousels that need initialising?
    	var $carousels = $posts.find('.owl-carousel');
    	if ($carousels.length) {
    		$.each($carousels, function() {
    			$carousel = $(this);
    			
    			//only init the carousel if there is more than one image
    			if ($carousel.find('.photo').length>1) {
					//init the slider once the images have loaded
	    			$carousel.waitForImages(function() {
	    				$(this).owlCarousel({
							loop: true,
							nav: true,
							autoHeight: true,
							mouseDrag: true,
							dots: false,
							items: 1,
							navText: ['<span></span>',
							          '<span></span>'] 
						});
					});
    			} else {
    				$carousel.removeClass('owl-carousel owl-theme').addClass('single-slide');
    			}
    		});
    	}
    	
    	//do we have some iframe videos?

    	//if we have any videos embedded via iframes we use javascript to make them responsive
    	var $allVideos = $posts.filter('.facebook, .adhoc').find('iframe[src*="player.vimeo.com"], \
    			iframe[src*="www.youtube.com"]'),
    		$fluidEl = $('.posts article').first();
    	
    	//console.log("got "+$allVideos.length+" videos to resize");
    	//do we have some videos?
    	if ($allVideos.length) {

	    	//console.log('length of videos: ', $allVideos.length);
	    	// Figure out and save aspect ratio for each video
	    	$allVideos.each(function() {
    			$(this)
	  	    	    .data('aspectRatio', this.height / this.width)	  	
	  	    	    // and remove the hard coded width/height
	  	    	    .removeAttr('height')
	  	    	    .removeAttr('width');	
	    	});
	
	    	// When the window is resized
	    	$(window).resize(function() {
	
	    	  // Resize all videos according to their own aspect ratio
	    	  $allVideos.each(function() {
	
	    	    var $el = $(this),
	    	    	newWidth = $el.parent().width();
	    	    
	    	    $el
	    	      .width(newWidth)
	    	      .height(newWidth * $el.data('aspectRatio'));
	
	    	  });
	
	    	// Kick off one resize to fix all videos on page load
	    	}).resize();
	    	
    	
    	}//end of check for videos
    	
    	//process any new instagram embeds
    	/*
    	var $instagrams = $posts.filter('.instagram');
    	if ($instagrams.length) {
    		if (window.instgrm && window.instgrm.Embeds) {
    			window.instgrm.Embeds.process();
    		}
    	}*/
    	
    };

    /*
     * Share buttons
     */
    var initialiseShare = function() {

		//twitter
    	/*
		$shareoverlay.find('a.twitter').click(function(e) {
			e.preventDefault();
			showPopup('https://twitter.com/intent/tweet?text='+encodeURIComponent(window.location.href));
		});*/

		//facebook
		var $fbshare = $('article.facebook footer .share a');
		if ($fbshare.length) {
			$fbshare.click(function(e) {
				e.preventDefault();
				showPopup('https://www.facebook.com/dialog/share?app_id='+fb_app_id+'&display=popup&href='+encodeURIComponent(this.href));
			});
		}
		/*
		//google
		$shareoverlay.find('a.google').click(function(e) {
			e.preventDefault();
			showPopup('https://plus.google.com/share?url='+encodeURIComponent(window.location.href));
		});

		//linkedin
		$shareoverlay.find('a.linkedin').click(function(e) {
			e.preventDefault();
			showPopup('https://www.linkedin.com/cws/share?url='+window.location.href);
		});*/

    };

    /**
     * open a popup window
     * Used by share buttons
     */
    var showPopup = function(url) {
    	window.open(url, 'popUpWindow', 'height=435,width=514,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes');
    };

    /**
     * For contact forms
     */
    var initialiseContactForm = function() {
    	if ($('form.wpcf7-form').length) {
    		
    		//if 'clientwidth' and 'clientheight' are present we populate then with the client width and height
    		var $widthfield = $('.wpcf7 input[name="clientwidth"]'),
    			$heightfield = $('.wpcf7 input[name="clientheight"]');
    		if ($widthfield.length) {
    			$widthfield.val(screen.width);
    		}
    		if ($heightfield.length) {
    			$heightfield.val(screen.height);
    		}

    		//insert the game name into the form
    		var $formfield = $('input.wpcf7-text[name="game"]');
    		if ($formfield.length && typeof game_name !='undefined') {
    			$formfield.val(game_name);
    		}
    		
    		//if the form has a 'mailchimp' field we send to MailChimp on a successful submit
    		var $checkbox = $('.wpcf7 input[name="mailchimp[]"]');
    		if ($checkbox.length
    				&& $('.wpcf7 input[name="email"]').length) {

    			$('.wpcf7').on('wpcf7:mailsent', function(event) {
    			//$('.wpcf7').on('wpcf7:submit', function(event) {
		    		//is the 'Subscribe to the SEGA mailing list' checkbox checked?
		  		  	if ($checkbox.is(':checked')) {
			  		  	var email = $('.wpcf7 input[name="email"]').val()
			  		  		nickname = ''
			  		  		$nickname = $('.wpcf7 input[name="nickname"]');
			  		  	//do we have a nickname?
			  		  	if ($nickname.length) {
			  		  		nickname = $nickname.val();
			  		  	}
			  		  	
			  		  	//send the subscription to MailChimp
				  		$.ajax({
				  		  type: "POST",
				  		  url: ajax_url,//set in the footer
				  		  data: {
					  		action: 'subscribe',
				  			email: email,
				  			nickname: nickname
				  		  },
				  		  success: function(data) {
				  			  console.log('success', data);
				  		  }
				  		});
		  		  	}
				});
    		}
    	}    		
    };

	
    return{init:init}//return items that are available outside

}(jQuery);

//document ready
jQuery(function() {
	sl.init();
});
